import React from 'react';
import { graphql, type PageProps } from 'gatsby';

import { DynamicContainer, Layout } from 'modules/ui';
import { MarkdownContent } from 'modules/mdx';
import Seo from 'components/SEO';

const StrapiCustomPage = ({ data }: PageProps<Queries.StrapiCustomPageQuery>) => {
    if (!data?.strapiPage) return null;

    const { seo, ...pageData } = data.strapiPage;

    const metaImage = seo?.metaImage;
    const metaImageSize = metaImage ? { width: metaImage.width ?? 1200, height: metaImage.height ?? 630 } : undefined;

    return (
        <>
            <Seo
                title={seo?.metaTitle ?? pageData.title ?? ''}
                description={seo?.metaDescription ?? pageData.content?.data?.childMdx?.excerpt}
                meta={seo?.keywords ? [{ name: 'keywords', content: seo.keywords }] : undefined}
                image={metaImage?.url ?? undefined}
                imageSize={metaImageSize}
            />
            <Layout>
                <DynamicContainer isFluid>
                    <MarkdownContent body={pageData?.content?.data?.childMdx?.body ?? ''} />
                </DynamicContainer>
            </Layout>
        </>
    );
};

export const pageQuery = graphql`
    query StrapiCustomPage($urlPath: String!) {
        strapiPage(urlPath: { eq: $urlPath }) {
            title
            content {
                data {
                    childMdx {
                        body
                        excerpt
                    }
                }
            }
            urlPath
            seo {
                metaDescription
                keywords
                metaTitle
                metaImage {
                    url
                    width
                    height
                }
            }
        }
    }
`;

export default StrapiCustomPage;
